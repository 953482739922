<script>
  import Base from '@factry/base'
  import DownTime from '@factry/downtime-manager'
  import EquipmentManager from '@factry/equipment-manager'
  import MaterialManager from '@factry/material-manager'
  import OrderManager from '@factry/order-manager'
  import WeighingManager from '@jacquesijs/weighing-manager'
</script>

<Base
  options={[EquipmentManager, DownTime, MaterialManager, OrderManager, WeighingManager]}
/>
