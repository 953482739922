/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma/scss/bulma.scss'
import { Context } from '@factry/components'
import * as Sentry from '@factry/sentry'
import { settings } from '@factry/core-js-client'
import { settings as settings3 } from '@factry/client'
import { getCurrentUserPrivileges } from '@factry/core-js-client/privilege'
import { initialize, addLocale, setDefaultLocale, setLocale } from '@factry/components/src/translate'
import { getTranslations } from '@factry/core-js-client/translation'
import { settings as userSettings } from '@factry/user-manager'
import App from './App.svelte'

import background from './assets/background.jpg'
import logo from './assets/jacques-ijs-logo.png'

Sentry.init({
  version: _FACTRY_VERSION,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE
})

settings.basePath = import.meta.env.VITE_BASE_PATH
settings.jwt = Context.getUserKey()

settings3.basePath = import.meta.env.VITE_BASE_PATH
settings3.jwt = Context.getUserKey()

addLocale('nl-be', 'en')
setDefaultLocale('nl-be')
const userLocale = Context.getUserLocale()
if (userLocale) setLocale(userLocale)
getTranslations().then(initialize)

if (!settings.jwt) window.location.hash = '/login'
if (settings.jwt) getCurrentUserPrivileges().then(privs => Context.setUserPrivileges(privs))

userSettings.background = background
userSettings.logo = logo
userSettings.logoAlt = 'Jacques IJs'

const app = new App({
  target: document.body,
})

export default app
